import { styled } from "styled-components";
import theme from "styled-theming";

import Icon from "components/Icon";
import { default as NavLink } from "components/Link";
import { Container as GridContainer } from "style/components/Page";
import { BodyM } from "style/components/Typography";
import { GUTTER } from "style/grid";
import { MQ } from "style/mediaQueries";

const textColor = theme("theme", {
    lightgray: "--text-on-neutral-primary",
    blue: "--text-on-blue-primary",
    cyan: "--text-on-cyan-primary",
});

const borderColor = theme("theme", {
    lightgray: "--text-on-neutral-link-alt-border",
    blue: "--text-on-blue-link-border",
    cyan: "--text-on-cyan-link-border",
});

const backgroundColor = theme("theme", {
    lightgray: "--background-default",
    blue: "--block-blue-default",
    cyan: "--block-cyan-default",
});

const darkenColor = theme("theme", {
    lightgray: "--color-black-o05",
    blue: "--color-black-o30",
    cyan: "--color-white-o15",
});

export const Section = styled.div`
    align-items: center;
    background: var(${backgroundColor});
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;

    &::after {
        background: var(${darkenColor});
        content: "";
        display: block;
        inset: 0;
        position: absolute;
    }
`;

export const Container = styled(GridContainer)`
    align-self: center;
    padding-bottom: 0.875rem;
    padding-right: 0;
    padding-top: 0.875rem;

    ${MQ.FROM_M} {
        padding: 0.875rem ${GUTTER.MD};
    }

    ${MQ.FROM_XL} {
        padding: 1.3125rem ${GUTTER.XL};
    }
`;

export const Links = styled.ul`
    display: flex;
    flex-direction: row;
    gap: 0.375rem;
    margin: 0;
    overflow: auto;
    padding-inline-start: 0;
    width: 100%;

    ${MQ.FROM_M} {
        flex-wrap: wrap;
    }
`;

export const Arrow = styled(Icon)`
    color: var(${textColor});
    height: 1.75rem;
    margin-right: 0.375rem;
    width: 1.75rem;
`;

export const Home = styled(Icon)`
    color: inherit;
    height: 1.75rem;
    vertical-align: -0.5rem;
    width: 1.75rem;
`;

export const LinkWrapper = styled(BodyM).attrs<{
    readonly $position: string;
    readonly $collapseItems: boolean;
}>(() => ({}))`
    color: var(${textColor});
    list-style: none;
    white-space: nowrap;
    z-index: 1;
    ${(props) =>
        props.$position === "middle" && props.$collapseItems && `display:none;`}

    &:first-child {
        display: block;
    }

    ${MQ.FROM_M} {
        display: block;
    }
`;

export const Link = styled(NavLink)`
    border: none;
    color: var(${textColor});

    span {
        transition:
            border-color 0.2s ease-in-out,
            color 0.2s ease-in-out;
        border-color: var(${borderColor});
    }

    &:hover {
        color: var(${textColor});

        span {
            border-bottom: 1px solid var(${textColor});
        }
    }
`;

export const Collapsed = styled.button.attrs<{
    $show: boolean;
}>(() => ({}))`
    all: unset;
    cursor: pointer;
    display: ${(props) => (props.$show ? "inline-block" : "none")};
    margin-left: 0.375rem;
    white-space: nowrap;

    ${MQ.FROM_M} {
        display: none;
    }
`;
