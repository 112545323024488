import React, { ReactElement, useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router";
import { ThemeProvider } from "styled-components";

import { A11y } from "style/components/A11Y";

import {
    Links,
    LinkWrapper,
    Link,
    Home,
    Arrow,
    Section,
    Container,
    Collapsed,
} from "./Breadcrumb.styled";
import BreadcrumbProps from "./BreadcrumbProps";

const Breadcrumb = ({
    theme = "lightgray",
    identifier,
    items,
}: BreadcrumbProps): ReactElement => {
    const [isCollapsed, setCollapsed] = useState<boolean>(true);
    const { pathname } = useLocation();

    useEffect(() => {
        setCollapsed(true);
    }, [pathname]);

    const toggleCollapsed = useCallback((): void => {
        setCollapsed(!isCollapsed);
    }, [isCollapsed]);

    const handleKeyDown = useCallback(
        (event: React.KeyboardEvent<HTMLButtonElement>): void => {
            if (event?.key === "Enter") {
                event.preventDefault();
                toggleCollapsed();
            }
        },
        [toggleCollapsed],
    );

    return (
        <ThemeProvider theme={{ theme: theme }}>
            <Section>
                <Container
                    role="navigation"
                    aria-label="Breadcrumb"
                    key={identifier || `BreadcrumbContent`}
                >
                    <Links as="ol">
                        {items?.map(
                            (navItem, index, items) =>
                                navItem?.url && (
                                    <LinkWrapper
                                        as="li"
                                        key={`navItem-${index}`}
                                        $position={
                                            index === 0
                                                ? "first"
                                                : index === items.length - 1
                                                  ? "last"
                                                  : "middle"
                                        }
                                        $collapseItems={isCollapsed}
                                    >
                                        {index !== 0 && (
                                            <Arrow icon={"arrowRight28"} />
                                        )}
                                        {index < items.length - 1 ? (
                                            <Link
                                                to={navItem.url}
                                                exact
                                                title={navItem.displayName}
                                                aria-current={
                                                    items.length - 1 === index
                                                        ? "page"
                                                        : ""
                                                }
                                                gtmClickType="breadcrumb"
                                            >
                                                {index === 0 ? (
                                                    <>
                                                        <A11y>
                                                            {
                                                                navItem.displayName
                                                            }
                                                        </A11y>
                                                        <Home icon={"home28"} />
                                                    </>
                                                ) : (
                                                    <span>
                                                        {navItem.displayName}
                                                    </span>
                                                )}
                                            </Link>
                                        ) : (
                                            <span>{navItem.displayName}</span>
                                        )}
                                        {items.length > 2 && index === 0 && (
                                            <Collapsed
                                                $show={isCollapsed}
                                                onClick={() =>
                                                    toggleCollapsed()
                                                }
                                                onKeyDown={(
                                                    event: React.KeyboardEvent<HTMLButtonElement>,
                                                ) => handleKeyDown(event)}
                                            >
                                                <Arrow icon={"arrowRight28"} />
                                                ...
                                            </Collapsed>
                                        )}
                                    </LinkWrapper>
                                ),
                        )}
                    </Links>
                </Container>
            </Section>
        </ThemeProvider>
    );
};

export default React.memo(Breadcrumb);
